import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContactBtn from '../contact/ContactBtn';
import Navbar from '../navbar/Navbar';
import LetsTalk from '../contact/LetsTalk';
import Footer from '../footer/Footer';
import blog from '../../assets/images/VFX Soft.png';
import blog1 from '../../assets/images/Rotocopy.png';
import blog2 from '../../assets/images/Match.png';
import blog3 from '../../assets/images/VFX Roto.png';
import LEARNMORE from '../../assets/images/learn_more (1).png';

function OurBlogs() {
  const navigate = useNavigate();

  return (
    <div className='bg-black'>
      <div className='bg-img'>
        <Navbar />
        <div className='md:mt-[10vh] mt-[6vh] xl:mb-[10vh] lg:mb-[7vh] md:mb-[5vh] mb-5'>
          <h1 className='patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium'>Our Blog</h1>
          <p className='text-white text-center lg:text-[20px] md:text-[14px] text-[10px] w-full 2xl:max-w-[70%] xl:max-w-[60%] lg:max-w-[75%] md:max-w-[80%] max-w-[90%] mx-auto' style={{ fontWeight: '300' }}>
            Explore our blogs to get the latest updates and insights on cutting-edge VFX techniques and industry trends. Stay informed with our expert analyses and innovative approaches to visual effects in film and media. Dive into our world of creativity and technology today!
          </p>
          <ContactBtn />
        </div>
      </div>
      <div className='max-w-1440 w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2'>
        <div className='blog1 p-4 our-blogs 2xl:h-[550px] xl:h-[500px] lg:h-[370px] h-[300px]'>
          <div className='flex items-center justify-center'>
            <img
              src={blog}
              alt='Sai Kumar'
              className="w-[100%] 2xl:h-[350px] xl:h-[350px] lg:h-[250px] md:h-[200px] h-[210px] xl:px-8 lg:px-4 md:px-3 px-4 md:pt-7 pt-6"
              onError={(e) => e.target.src = 'path/to/fallback-image.jpg'}
            />
          </div>
          <p className='text-white font-bold lg:text-[28px] text-[12px] mx-auto w-[89%] pt-1'>Sai Kumar</p>
          <div className='flex justify-between mx-auto w-[89%]'>
            <p className='text-white font-normal xl:text-[20px] lg:text-[16px] text-[10px]'>Content</p>
            <img
              src={LEARNMORE}
              alt='Learn more'
              className='xl:w-[135px] lg:w-[100px] md:w-[70px] w-[60px] xl:h-[20px] lg:h-[15px] md:h-[13px] h-[10px] cursor-pointer'
              onClick={() => ({ title: 'Sai Kumar', role: 'Content', image: blog }, navigate('/best-vfx-software'))}
            />
          </div>
        </div>
        <div className='blog2 p-4 our-blogs 2xl:h-[550px] xl:h-[500px] lg:h-[370px] h-[300px]'>
          <div className='flex items-center justify-center'>
            <img
              src={blog1}
              alt='Sai Kumar'
              className="w-[100%] 2xl:h-[350px] xl:h-[350px] lg:h-[250px] md:h-[200px] h-[210px] xl:px-8 lg:px-4 md:px-3 px-4 md:pt-7 pt-6"
              onError={(e) => e.target.src = 'path/to/fallback-image.jpg'}
            />
          </div>
          <p className='text-white font-bold lg:text-[28px] text-[12px] mx-auto w-[89%] pt-1'>Sai Kumar</p>
          <div className='flex justify-between mx-auto w-[89%]'>
            <p className='text-white font-normal xl:text-[20px] lg:text-[16px] text-[10px]'>Content</p>
            <img
              src={LEARNMORE}
              alt='Learn more'
              className='xl:w-[135px] lg:w-[100px] md:w-[70px] w-[60px] xl:h-[20px] lg:h-[15px] md:h-[13px] h-[10px] cursor-pointer'
              onClick={() => ({ title: 'Sai Kumar', role: 'Content', image: blog1 }, navigate('/rotoscoping-services'))}
            />
          </div>
        </div>
        <div className='blog3 p-4 our-blogs 2xl:h-[550px] xl:h-[500px] lg:h-[370px] h-[300px]'>
          <div className='flex items-center justify-center'>
            <img
              src={blog2}
              alt='Sai Kumar'
              className="w-[100%] 2xl:h-[350px] xl:h-[350px] lg:h-[250px] md:h-[200px] h-[210px] xl:px-8 lg:px-4 md:px-3 px-4 md:pt-7 pt-6"
              onError={(e) => e.target.src = 'path/to/fallback-image.jpg'}
            />
          </div>
          <p className='text-white font-bold lg:text-[28px] text-[12px] mx-auto w-[89%] pt-1'>Sai Kumar</p>
          <div className='flex justify-between mx-auto w-[89%]'>
            <p className='text-white font-normal xl:text-[20px] lg:text-[16px] text-[10px]'>Content</p>
            <img
              src={LEARNMORE}
              alt='Learn more'
              className='xl:w-[135px] lg:w-[100px] md:w-[70px] w-[60px] xl:h-[20px] lg:h-[15px] md:h-[13px] h-[10px] cursor-pointer'
              onClick={() => ({ title: 'Sai Kumar', role: 'Content', image: blog2 }, navigate('/matchmoving-vfx'))}
            />
          </div>
        </div>
        <div className='blog4 p-4 our-blogs 2xl:h-[550px] xl:h-[500px] lg:h-[370px] h-[300px]'>
          <div className='flex items-center justify-center'>
            <img
              src={blog3}
              alt='Sai Kumar'
              className="w-[100%] 2xl:h-[350px] xl:h-[350px] lg:h-[250px] md:h-[200px] h-[210px] xl:px-8 lg:px-4 md:px-3 px-4 md:pt-7 pt-6"
              onError={(e) => e.target.src = 'path/to/fallback-image.jpg'}
            />
          </div>
          <p className='text-white font-bold lg:text-[28px] text-[12px] mx-auto w-[89%] pt-1'>Sai Kumar</p>
          <div className='flex justify-between mx-auto w-[89%]'>
            <p className='text-white font-normal xl:text-[20px] lg:text-[16px] text-[10px]'>Content</p>
            <img
              src={LEARNMORE}
              alt='Learn more'
              className='xl:w-[135px] lg:w-[100px] md:w-[70px] w-[60px] xl:h-[20px] lg:h-[15px] md:h-[13px] h-[10px] cursor-pointer'
              onClick={() => ({ title: 'Sai Kumar', role: 'Content', image: blog3 }, navigate('/best-vfx-rotomation-software'))}
            />
          </div>
        </div>
      </div>
      <div className='xl:mt-[15vh] my-[10vh]'>
        <LetsTalk />
      </div>
      <div className='md:mt-[15vh] mt-[7vh]'>
        <Footer />
      </div>
    </div>
  );
}

export default OurBlogs;
