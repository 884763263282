import React, { useState } from 'react';
import LOGO from "../../assets/images/LOGO copy.png";
import { useNavigate, useLocation } from "react-router-dom";
import TOGGLE from '../../assets/images/ic_menu.png'

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const isHomePage = location.pathname === '/';
    const isContactPage = location.pathname === '/contact';
    const isServices = location.pathname === '/services';
    const isOurWork = location.pathname === '/ourWork';
    const isShowreel = location.pathname === '/enterpassword';
    const isOurBlogs = location.pathname === '/blog';
    const isAboutvfx = location.pathname === '/about';

    return (
        <div className='md:pt-3'>
            <div className="max-w-1440 w-[90%] mx-auto  overflow-hidden">
                <nav className="flex flex-wrap items-center justify-between md:w-full text-lg text-gray-700" onClick={toggleMenu}>
                    <img src={TOGGLE} alt='toggle' className="h-5 w-5 cursor-pointer md:hidden block md:mt-0 mt-[3vh]" />

                    <div className="flex justify-center md:justify-start w-full md:w-auto md:mt-0 mt-[-25px]">
                        <a onClick={() => navigate("/")}>
                            <img src={LOGO} alt='logo'
                                className="xl:w-[89px] lg:w-[80px] md:w-[48px] w-[44px] xl:h-[89px] lg:h-[80px] md:h-[48px] h-[44px]" />
                        </a>
                    </div>
                    <div className={`w-full md:flex md:items-center md:w-auto ${isMenuOpen ? "block" : "hidden"}`} id="menu">
                        <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 md:flex justify-center items-center xl:gap-4">
                            <li className='cursor-pointer' onClick={() => navigate("/")}>
                                <a className={`inter  lg:text-[20px] md:text-[15px] text-[12px] md:p-4 py-2 block ${isHomePage ? 'text-gradient' : 'text-white'} hover:text-[#b9644c]`}>Home</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/about")}>
                                <a className={`inter md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isAboutvfx ? 'text-gradient' : 'text-white'}  font-medium  hover:text-[#b9644c]`}>About</a>
                            </li>
                            {/* <li className='cursor-pointer' onClick={() => navigate("/OurWork")}>
                                <a className={`inter md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px]  ${isOurWork ? 'text-gradient' : 'text-white'} font-medium  hover:text-[#b9644c]`}>Our Work</a>
                            </li> */}
                            <li className='cursor-pointer' onClick={() => navigate("/services")}>
                                <a className={`inter md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px]  font-medium ${isServices ? 'text-gradient' : 'text-white'} hover:text-[#b9644c]`}>Services</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/enterpassword")}>
                                <a className={`inter md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px]  ${isShowreel ? 'text-gradient' : 'text-white'} font-medium hover:text-[#b9644c]`}>Show Reel</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/blog")}>
                                <a className={`inter md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px]  font-medium ${isOurBlogs ? 'text-gradient' : 'text-white'} hover:text-[#b9644c]`}>Blog</a>
                            </li>
                            <li>
                                <div onClick={() => navigate("/contact")}>
                                    <button className={` block lg:text-[17px] md:text-[14px] text-[11px] border-[1.5px] hover:border-[#b9644c]  hover:text-[#b9644c] rounded-[8px] lg:w-[149px] md:w-[120px] w-[90px] lg:h-[38px] h-[34px] bg-[#232145] ${isContactPage ? 'text-gradient border-[#ca5a56]' : 'text-white border-white'}`}>
                                        Contact Us
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar;
