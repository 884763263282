import React, { useState } from 'react'
import Navbar from '../navbar/Navbar'
import TITLE from '../../assets/images/title(1).png'
import About from '../AboutNamaha/About'
import Services from '../services/Services'
import HowWeWork from '../HowWeWork/HowWeWork'
import OurWork from '../ourwork/OurWork'
import TrustedClients from '../trustedclients/TrustedClients'
import Footer from '../footer/Footer'
import ReactPlayer from 'react-player';
import LetsTalk from '../contact/LetsTalk'
import { useNavigate } from "react-router-dom";
import THUMBNAIL from '../../assets/images/thumbnail.jpg'

function HomePage() {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div className='bg-black '>
      <div className='bg-img pb-7'>
        <Navbar />
        <div>
          <div className='flex justify-center items-center md:mt-[12vh] mt-[7vh]'>
            <img src={TITLE} alt='title' className='2xl:w-[85vh] xl:w-[70vh] lg:w-[60vh] md:w-[50vh] w-[40vh]' />
          </div>
          <div className='flex justify-center 2xl:mr-[6vh] xl:mr-[2vh] lg:mr-0 md:mr-[2vh] mt-3'>
            <button onClick={() => navigate("/contact")} className='btn border border-[#9F1E0B] text-white lg:w-[173px] md:w-[110px] w-[94.33px] rounded-[8px]  lg:text-[16px] md:text-[11px] text-[9px] xl:h-[45px] lg:h-[40px] md:h-[35px] h-[26.17px] bg-[#150503]'>Contact Us</button>
          </div>
        </div>
      </div>
      <div className='lg:mt-[15vh] mt-[9vh]'>
        <About />
      </div>
      <div className='lg:mt-[15vh] mt-[9vh]'>
        <Services />
      </div>
      <div className='lg:mt-[15vh] mt-[9vh]'>
        <HowWeWork />
      </div>
      {/* <div className='lg:mt-[15vh] mt-[9vh]'>
        <OurWork />
      </div> */}
      <div className='lg:mt-[15vh] mt-[8vh]'>
        {/* <TrustedClients /> */}
        <div className='max-w-1440 w-full mx-auto'>
      {!isPlaying ? (
        <button onClick={handlePlayVideo} className='relative'>
          <img src={THUMBNAIL} alt='Video Thumbnail' className='w-full h-auto' />
          <div className='absolute inset-0 flex items-center justify-center'>
            <span className='text-white text-3xl'>&#9658;</span>
          </div>
        </button>
      ) : (
        <ReactPlayer
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onContextMenu={e => e.preventDefault()}
          url='https://namahavfx.com/videos_content/Office%20Video.mp4'
          playing={true}
          controls={true}
          width='100%'
        />
      )}
    </div>
      </div>
      <div className='lg:mt-[15vh] mt-[10vh]'>
        <LetsTalk />
      </div>
      <div className='md:mt-[15vh] mt-[7vh]'>
        <Footer />
      </div>
    </div>
  )
}

export default HomePage