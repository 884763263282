import React from 'react'
import { useNavigate } from "react-router-dom";

function LetsTalk() {
  const navigate = useNavigate();

  return (
    <div className='max-w-1440 w-[90%] mx-auto mt-[10vh]'>
      <div className='border border-[#38B744] bg-[#0f1d0f] w-[100%]  md:p-12 p-4 rounded-[10px]'>
        <h1 className='text-white  xl:text-[40px] lg:text-[35px] md:text-3xl text-[28px]'>Let's Talk</h1>
        <div className='md:flex justify-between'>
          <div className='xl:w-[70%] lg:w-[80%] md:w-[75%] w-[100%] mt-1' >
            <p className='mt-2  xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] text-white'>Join a VFX studio that will bring your dream idea to life with high-definition, mesmerizing visual masterpieces.</p>
          </div>
          <div className='mt-2 flex align-end justify-end'>
            <button onClick={() => navigate("/Contact")} className='btn3 md:border-[2px] border border-white text-white xl:text-[16px] md:text-[14px] text-[8.72px] xl:w-[149px] lg:w-[120px] w-[94.33px] md:h-[40px] h-[32px]  rounded-[7px]' style={{ background: '#2d392d' }}>Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LetsTalk