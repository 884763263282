import React from 'react'
import BRAND1 from '../../assets/images/brand1.png'
import BRAND2 from '../../assets/images/brand3.png'
import BRAND3 from '../../assets/images/brand2.png'
import BRAND4 from '../../assets/images/brand4.png'

function TrustedClients() {
  return (
    <div style={{ background: '#0A0A0A' }}>
      <div className='max-w-1440 w-[90%] mx-auto'>
        <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] md:mb-[-30px]'>Trusted Clients</h1>
        <div className='flex flex-wrap md:flex-nowrap xl:gap-[7vh] lg:gap-[4vh] md:gap-[3vh] gap-0 md:mb-0 mb-4'>
          <div className='flex flex-[0_0_40%] md:flex-auto  md:mr-0 mr-9 '>
            <img src={BRAND1} alt='brand1' className='w-[220px] h-[130px]' />
          </div>
          <div className='flex flex-[0_0_40%] md:flex-auto '>
            <img src={BRAND2} alt='brand2' className='w-[220px] h-[130px]' />
          </div>
          <div className='flex flex-[0_0_40%] md:flex-auto md:mr-0 mr-9'>
            <img src={BRAND3} alt='brand3' className='w-[220px] h-[130px]' />
          </div>
          <div className='flex flex-[0_0_40%] md:flex-auto '>
            <img src={BRAND4} alt='brand4' className='w-[220px] h-[130px]' />
          </div>
          <div className='flex flex-[0_0_40%] md:flex-auto '>
            <img src={BRAND1} alt='brand1' className='w-[220px] h-[130px]' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TrustedClients