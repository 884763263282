import React from 'react'
import SERVICES1 from '../../assets/images/Vector 8 (1).png'
import SERVICES2 from '../../assets/images/Vector 8 (2).png'
import SERVICES3 from '../../assets/images/Mask group (2).png'
import SERVICES4 from '../../assets/images/Mask group (3).png'
import SERVICES5 from '../../assets/images/Mask group (6).png'
import SERVICES6 from '../../assets/images/Mask group (4).png'

const servicesData = [
  { id: 1, imageSrc: SERVICES1, text: 'Rotoscopy' },
  { id: 2, imageSrc: SERVICES2, text: 'Paint' },
  { id: 3, imageSrc: SERVICES3, text: 'Matchmove' },
  { id: 4, imageSrc: SERVICES4, text: 'Rotomation' },
  { id: 5, imageSrc: SERVICES5, text: 'Compositing' },
  { id: 6, imageSrc: SERVICES6, text: 'Fx' },
];

function Services() {
  return (
    <div>
      <div className='max-w-1440 w-[90%] mx-auto md:mt-[10vh]'>
        <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] font-bold'>Our Services</h1>
        <p className=' text-white mt-2 xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] xl:mb-[2vh] md:mb-[2vh] mb-[1vh]' style={{ fontWeight: 300 }}>Namaha VFX is diligent and passionate about making soul-stirring VFX presentations. Our team crafts high-quality visual effects with precision down to the last millimeter, contributing to various award-winning projects. Whether it's mesmerizing CGI, breathtaking animations, or seamless integration of visual effects, we deliver excellence in every frame.</p>
      </div>
      <div className='max-w-1440 w-[90%] mx-auto grid grid-cols-2 md:grid-cols-3 md:gap-9 '>
        {servicesData.map(service => (
          <div key={service.id} className=" xl:p-6 lg:p-4 p-3 bg-vector3 w-full h-full text-center shadow-lg">
            <img src={service.imageSrc} alt='RotoScopy' className="w-[100%] h-auto" />
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>{service.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services