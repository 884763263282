import './App.css';
import MainRoute from './route/MainRoute';

function App() {
  return(
  <div>
    <MainRoute/>
  </div>
  );
}
export default App;
