import React, { useState } from 'react';
import IMAGE from '../../assets/images/Mask group (5).png';
import SEEMORE from '../../assets/images/learn_more.png'
import LEFTARROW from '../../assets/images/leftarrow.png'

function OurWork() {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div className='max-w-1440 w-[90%] mx-auto'>
      <div className='flex justify-between w-full max-w-[98%]'>
        <div className='2xl:w-[80%]'>
          <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] font-bold'>Our Work</h1>
          <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] mt-2 mb-12'>
            Namaha VFX collaborates with the world's most creative filmmakers, advertising agencies, post studios, and media houses to produce the most iconic, amazing, and vibrant work. Our team of superhumans delivers spell-bounding visual effects solutions that bring unforgettable stories to life.
          </p>
        </div>
        <div>
          <button onClick={handleToggle} className='text-[#2f82ca]'>
            {showMore ? (
              <div className='flex gap-2 items-center justify-center'>
                <img src={LEFTARROW} alt='left-arrow' className='2xl:h-[15px] xl:h-[12px] lg:h-[11px] md:h-[10px] h-[9px]' />
                <p className='xl:text-[20px] lg:text-[16px] md:text-[13px] text-[12px]'>back</p>
              </div>
            ) : (
              <img src={SEEMORE} alt="See More Arrow" className="inline-block ml-2 2xl:w-[110px] xl:w-[230px] lg:w-[200px] md:w-[140px] w-[250px]" />
            )}
          </button>
        </div>
      </div>
      <div className="flex overflow-x-auto hide-scrollbar 2xl:gap-[5.3vh] xl:gap-[5vh]  md:gap-[3vh] gap-4 w-[100%] ">
      <div  className=" lg:p-3 p-2 bg-vector2 md:w-[30%] w-[41%]  xl:h-[500px] lg:h-[330px] md:h-[250px] text-center shadow-lg flex-none ">
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',}} className='2xl:h-[450px] xl:h-[420px] lg:h-[290px] md:h-[225px] h-[150px] mx-5'>
              <img src='https://namahavfx.com/wp-content/uploads/2023/09/DR-691x1024.jpg' alt='ourwork' className="md:w-[100%] w-[92%] md:h-auto h-[150px] 2xl:pt-0 xl:pt-2 pt-0" />
              </div>
              {/* <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>{item.title}</p> */}
            </div>
            <div  className=" lg:p-3 p-2 bg-vector2 md:w-[30%] w-[41%]  xl:h-[500px] lg:h-[330px] md:h-[250px] text-center shadow-lg flex-none ">
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',}} className='2xl:h-[450px] xl:h-[420px] lg:h-[290px] md:h-[225px] h-[150px] mx-5'>
              <img src='https://namahavfx.com/wp-content/uploads/2023/09/pino-1-691x1024.jpg' alt='ourwork' className="md:w-[100%] w-[92%] md:h-auto h-[150px] 2xl:pt-0 xl:pt-2 pt-0" />
              </div>
            </div>
            <div  className=" lg:p-3 p-2 bg-vector2 md:w-[30%] w-[41%]  xl:h-[500px] lg:h-[330px] md:h-[250px] text-center shadow-lg flex-none ">
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',}} className='2xl:h-[450px] xl:h-[420px] lg:h-[290px] md:h-[225px] h-[150px] mx-5'>
              <img src='https://namahavfx.com/wp-content/uploads/2023/09/Falcon-691x1024.jpg' alt='ourwork' className="md:w-[100%] w-[92%] md:h-auto h-[150px] 2xl:pt-0 xl:pt-2 pt-0" />
              </div>
            </div>
        {showMore && (
          <>
           <div  className=" lg:p-3 p-2 bg-vector2 md:w-[30%] w-[41%]  xl:h-[500px] lg:h-[330px] md:h-[250px] text-center shadow-lg flex-none ">
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',}} className='2xl:h-[450px] xl:h-[420px] lg:h-[290px] md:h-[225px] h-[150px] mx-5'>
              <img src='https://namahavfx.com/wp-content/uploads/2023/09/olympics.jpg' alt='ourwork' className="md:w-[100%] w-[92%] md:h-auto h-[150px] 2xl:pt-0 xl:pt-2 pt-0" />
              </div>
            </div>
            <div  className=" lg:p-3 p-2 bg-vector2 md:w-[30%] w-[41%]  xl:h-[500px] lg:h-[330px] md:h-[250px] text-center shadow-lg flex-none ">
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',}} className='2xl:h-[450px] xl:h-[420px] lg:h-[290px] md:h-[225px] h-[150px] mx-5'>
              <img src='https://namahavfx.com/wp-content/uploads/2023/09/war-731x1024.jpg' alt='ourwork' className="md:w-[100%] w-[92%] md:h-auto h-[150px] 2xl:pt-0 xl:pt-2 pt-0" />
              </div>
            </div>
           
          </>
        )}
      </div>
    </div>
  );
}

export default OurWork;

