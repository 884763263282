import React from 'react'
import GIRLIMAGE from '../../assets/images/Mask group.png'
function About() {
   return (
      <div>
         <div className='max-w-1440 w-[90%] mx-auto md:flex gap-4'>
            <div className='xl:w-[42%] w-[100%] '>
               <img src={GIRLIMAGE} alt='GIRLIMAGE' className='xl:w-[513.47px] xl:h-[516.2px] ' />
            </div>
            <div className='xl:w-[58%] w-[100%] '>
               <div className='xl:mt-6'>
                  <button className='btn2 border border-white  text-white lg:w-[174px] md:w-[120px] w-[93.92px] rounded-[8px] lg:text-[16px] md:text-[12px] text-[9px] xl:h-[45px] lg:h-[40px] md:h-[35px] h-[27px]' style={{ background: '#272727' }}>About Namaha VFX</button>
                  <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] xl:mt-4 md:mt-2 mt-0 font-bold'>Our Legacy</h1>
                  <p className='fontWeight text-white  xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] xl:mt-3 md:mt-1 mt-0'>Want to tell the next big story? Namaha VFX is a top post-production studio creating high-quality VFX series for feature films, commercials, OTT platforms, and complete rotoscoping production. Our team of passionate VFX artists believes in delivering innovative Visual Effects solutions worldwide. Whether it is an element, creative transition, or atmospheric effects, our team oversees the illusions while seamlessly integrating realistic visuals into your vision. We offer refined production-quality work, ensuring every visual effect enhances and elevates your project. </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default About