import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import ContactBtn from '../contact/ContactBtn';
import Navbar from '../navbar/Navbar';
import TrustedClients from '../trustedclients/TrustedClients';
import LetsTalk from '../contact/LetsTalk';
import Footer from '../footer/Footer';

const data = [
  {
        url: 'https://namahavfx.com/videos_content/roto.mp4',
        title: 'Rotoscopy',
      },
      {
        url: 'https://namahavfx.com/videos_content/paint.mp4',
        title: 'Paint',
      },
      {
        url: 'https://namahavfx.com/videos_content/match%20move.mp4',
        title: 'Matchmove',
      },
      {
        url: '',
        title: 'Rotomation',
      },
      {
        url: 'https://namahavfx.com/videos_content/compositing.mp4',
        title: 'Compositing',
      },
      {
        url: 'https://namahavfx.com/videos_content/fx.mp4',
        title: 'Fx',
      },
];

function ShowReel() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const openModal = (url) => {
    setCurrentVideo(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo('');
  };

  return (
    <div className='bg-black'>
      <div className='bg-img '>
        <Navbar />
        <div className='md:my-[10vh] my-[6vh] '>
          <h1 className='patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium'>Show Reel</h1>
          <p className=' text-white text-center xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] w-full 2xl:max-w-[65%] xl:max-w-[60%] lg:max-w-[75%] md:max-w-[80%] max-w-[90%] mx-auto'>
            Get ready to delve into some of our most remarkable projects.
            See what are the best practices of artistry and innovation we use to craft outstanding and spellbound projects.
            Explore our showreels to discover why Namaha VFX is one of the leading forces in the visual effects industry.
          </p>
          <ContactBtn />
        </div>
      </div>
      <div className='max-w-1440 w-[90%] mx-auto '>
        <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] font-bold mt-[10vh]'>Show Reel</h1>
        <p className='text-white lg:text-[20px] md:text-[14px] text-[10px] mt-2 mb-6'>
          Watch our visual effects breakdown reels to learn more about Namaha VFX and discover how we create breathtaking scenes in our award-winning projects. We specialize in diverse VFX services, crafting iconic videos that showcase our team's expertise and brilliance. Step into a world where we elevate your ideas to new heights.
        </p>
        <div className='grid grid-cols-2 md:grid-cols-3 md:gap-[2vh] gap-[1vh]'>
          {data.map((item, index) => (
            <div
              key={index}
              className="bg-vector3 text-center 2xl:h-[530px] xl:h-[500px] lg:h-[350px] md:h-[260px] h-[210px] xl:mt-4 lg:mt-1 mt-3 2xl:px-6 xl:px-5 px-3 2xl:pt-5 xl:pt-6 pt-5"
              onClick={() => openModal(item.url)}
            >
              <div style={{
                margin: 'auto',
                clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
                overflow: 'hidden',
                width: '100%',
              }} className='2xl:h-[430px] xl:h-[390px] lg:h-[270px] md:h-[200px] h-[150px]'>
                <ReactPlayer
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={e => e.preventDefault()}
                  url={item.url}
                  playing={false}
                  controls={true}
                  width='100%'
                  height='100%' />
              </div>
              <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className='xl:mt-[15vh] mt-[10vh]'>
        <LetsTalk />
      </div>
      <div className='md:mt-[15vh] mt-[7vh]'>
        <Footer />
        <div className="App">
          <p>hello</p>
        </div>
      </div>

      {modalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}>
            <button onClick={closeModal} className="close-button">X</button>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <ReactPlayer
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              url={currentVideo}
              playing={true}
              controls={true}
              width='100%'
              height='100%'
            />
          </div>
        </div>
      )}

      <style jsx="value.toString()">{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.75);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000; /* Ensure the modal is on top of other content */
        }

        .modal-content {
          position: relative;
          width: 80%;
          height: 80%;
          background: black;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: #333;
          color: white;
          border: none;
          padding: 10px;
          border-radius: 50%;
          cursor: pointer;
          font-size: 18px;
        }

        .close-button:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
}

export default ShowReel;
