import React from 'react'
import MEN from '../../assets/images/img.png'
import ONE from '../../assets/images/01.png'
import TWO from '../../assets/images/02.png'
import THREE from '../../assets/images/03.png'

function HowWeWork() {
  return (
    <div style={{ background: '#0A0A0A' }}>
      <div className='max-w-1440 w-[90%] mx-auto' >
        <div className=''>
          <h1 className='text-white xl:text-[40px] lg:text-[35px] md:text-[25px] text-[28px] font-bold'>How We Work ?</h1>
          <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] xl:mb-[1vh] md:mb-[2vh] mb-[3vh] w-full md:max-w-[50%]'>
            We create visual effects and transform your idea into reality with a process that beats the industry's best timelines and high standards.
          </p>
        </div>
        <div className='flex flex-col md:flex-row'>
          <div className='div2 md:w-[50%] order-1 md:order-2 flex justify-end md:mt-[-6vh]' >
            <img src={MEN} alt='men' className='w-[513.47px] xl:h-[516.2px]' />
          </div>
          <div className='div1 md:w-[50%] order-2 md:order-1  '>
            <img src={ONE} alt='one' className='xl:w-[85%]   w-[90%] mt-4' />
            <img src={TWO} alt='one' className='xl:w-[85%]   w-[90%] mt-4' />
            <img src={THREE} alt='one' className='xl:w-[85%]   w-[90%] mt-4' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowWeWork