import React from 'react'
import Navbar from '../navbar/Navbar'
import ContactBtn from '../contact/ContactBtn'
import TrustedClients from '../trustedclients/TrustedClients'
import LetsTalk from '../contact/LetsTalk'
import Footer from '../footer/Footer'

const workItems = [
  { id: 1, imageSrc:'https://namahavfx.com/wp-content/uploads/2023/09/DR-691x1024.jpg', title: 'Rotoscopy' },
  { id: 2, imageSrc: 'https://namahavfx.com/wp-content/uploads/2023/09/pino-1-691x1024.jpg', title: 'Paint' },
  { id: 3, imageSrc:'https://namahavfx.com/wp-content/uploads/2023/09/Falcon-691x1024.jpg', title: 'Matchmove' },
  { id: 4, imageSrc: 'https://namahavfx.com/wp-content/uploads/2023/09/olympics.jpg', title: 'Rotoscopy' },
  { id: 5, imageSrc: "https://namahavfx.com/wp-content/uploads/2023/09/war-731x1024.jpg", title: 'Paint' },
 

];

function OurWorkMainPage() {
  return (
    <div className='bg-black'>
      <div className='bg-img  '>
        <Navbar />
        <div className='md:mt-[10vh] mt-[6vh] '>
          <h1 className='patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium'>Our Work</h1>
          <p className='text-white text-center  lg:text-[20px] md:text-[14px] text-[10px] w-full xl:max-w-[60%] lg:max-w-[90%] md:max-w-[93%] max-w-[90%] mx-auto' style={{ fontWeight: '300' }}>
            Team up with us to get the most iconic and vibrant work.
            With over a decade of experience, Namaha VFX innovates beyond imagination, crafting spellbinding
            solutions for our clients worldwide. Join us to bring your ideas to life with creativity and expertise.</p>
          <ContactBtn />
        </div>
      </div>
      <div className='max-w-1440 w-[90%] mx-auto '>
        <h1 className='text-white lg:text-[40px] text-xl font-bold md:mt-[20vh] mt-[5vh]'>Our Work</h1>
        <p className='text-white  lg:text-[20px] md:text-[14px] text-[10px] mt-2 mb-7'>
          Among the 500+ projects we have completed, here are some standout works that showcase the depth of our expertise in the VFX industry. At Namaha VFX, we love taking on challenges and crafting success through our outstanding results. Discover our portfolio and see how we can breathe life into your vision.
        </p>
        <div className='w-full mx-auto grid grid-cols-2 md:grid-cols-3 md:gap-9 gap-2 mt-2'>
          {workItems.map(item => (
            // <div key={item.id} className=" xl:p-6 lg:p-4 p-3 bg-vector2 w-full h-full text-center ">
            //   <div style={{
            //     margin: 'auto',
            //     clipPath: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)',
            //     overflow: 'hidden',
            //     width: '100%',}} className='2xl:h-[490px] xl:h-[390px] lg:h-[270px] md:h-[200px] h-[150px]'>
            //   <img src={item.imageSrc} alt={item.title} className="w-[100%] h-auto " />
            //   </div>
            // </div>
            <div key={item.id} className="lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg " >
            <img src={item.imageSrc} alt='RotoScopy' className="w-[100%]  2xl:h-[500px]  xl:h-[440px] lg:h-[300px] md:h-[230px] h-[180px] pt-0  pb-2" style={{clipPath: 'polygon(8% 0, 100% 0, 100% 100%, 0 99%, 0 8%)'}}/>
            {/* <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>erd</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>re</p> */}
          </div>
          ))}
        </div>
      </div>
      <div className='my-[10vh]'>
        <TrustedClients />
      </div>
      <LetsTalk />
      <div className='  md:mt-[15vh] mt-[7vh]'>
        <Footer />
      </div>
    </div>
  )
}

export default OurWorkMainPage