import React from 'react'
import { useNavigate } from 'react-router-dom'

function ContactBtn() {
  const navigate = useNavigate();

  return (
    <div className='flex justify-center mt-3'>
      <button onClick={() => navigate("/Contact")} className='btn border-[1.5px] border-[#9F1E0B] text-white lg:w-[173px] md:w-[120px] w-[94.33px] rounded-[8px]  lg:text-[16px]  text-[11px] xl:h-[45px] lg:h-[40px] md:h-[35px] h-[26.17px] bg-[#1c0c0a]'>Contact Us</button>
    </div>

  )
}

export default ContactBtn