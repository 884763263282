import React from 'react'
import LOGO from '../../assets/images/LOGO copy.png'
import FB from '../../assets/images/fb.png'
import TWITTER from '../../assets/images/twitter (2).png'
import INSTA from '../../assets/images/insta (1).png'
import LINKEDIN from '../../assets/images/linkedin.png'
import CALL from '../../assets/images/call-calling.png'
import MAIL from '../../assets/images/Group 47498.png'
import LOCATION from '../../assets/images/Group 47497.png'
import COPYRIGHT from '../../assets/images/copyright (1).png'
import TPN from '../../assets/images/tpn.png'
import { Link, useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    return (
        <div className='max-w-1440 w-[90%] mx-auto'>
            <div className="md:flex lg:space-x-36 md:space-x-6 space-x-4 md:p-4 ">
                <div className="flex-1 md:p-0 p-4">
                    <img src={LOGO} alt='logo' className='lg:w-[80px] md:w-[60px]  w-[40px]' />
                    <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] mt-4'>Namaha VFX, an award-winning visual
                        effects company, excels in using emerging
                        technologies and deep expertise to
                        deliver exceptional results.</p>
                    <div className='flex gap-[12px] mt-5'>
                        <a href="https://www.facebook.com/NamahaVfxstudios" target="_blank" rel="noopener noreferrer">
                            <img src={FB} alt='facebook' className='lg:w-[44.22px] lg:h-[44.22px] md:h-6  h-5 cursor-pointer' />
                        </a>
                        {/* <img src={TWITTER} alt='twitter' className='lg:w-[44.22px] lg:h-[44.22px] md:h-6  h-5 cursor-pointer' /> */}
                        <a href="https://www.instagram.com/namaha_vfx_studios/" target="_blank" rel="noopener noreferrer">
                            <img src={INSTA} alt='insta' className='lg:w-[44.22px] lg:h-[44.22px] md:h-6 h-5 cursor-pointer' />
                        </a>
                        <a href="https://www.linkedin.com/company/namaha-vfx-studios7/" target="_blank" rel="noopener noreferrer">
                            <img src={LINKEDIN} alt='linkedin' className='lg:w-[44.22px] lg:h-[44.22px] md:h-6  h-5 cursor-pointer' />
                        </a>
                    </div>
                    <div className='mt-6'>
                        <img src={TPN} alt='tpn' className='lg:w-[134.97px] md:w-[80px] w-[70px] lg:h-[57.5px] md:h-[30px]' />
                    </div>
                </div>
                <div className='flex'>
                    <div className="flex-1  md:mt-0 mt-4">
                        <h1 className=' xl:text-[30px] lg:text-[25px] text-[24px] text-white'>About</h1>
                        <div className='flex items-center gap-2 md:mt-3 mt-2'>
                            <div className="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full " ></div><p onClick={() => navigate('/about')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'> About Us</p>
                        </div>
                        {/* <div className='flex items-center gap-2 mt-1'>
                            <div class="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full"></div><p onClick={() => navigate('/OurWork')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'>Our Work</p>
                        </div> */}
                        <div className='flex items-center gap-2 mt-1'>
                            <div className="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full"></div><p onClick={() => navigate('/services')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'>Services</p>
                        </div>
                        <div className='flex items-center gap-2 mt-1'>
                            <div className="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full"></div><p onClick={() => navigate('/showreel')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'> Show Reels</p>
                        </div>
                        <div className='flex items-center gap-2 mt-1'>
                            <div className="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full"></div><p onClick={() => navigate('/blog')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'>Blogs</p>
                        </div>
                        <div className='flex items-center gap-2 mt-1'>
                            <div className="md:h-2 h-1 md:w-2 w-1 bg-white rounded-full"></div><p onClick={() => navigate('/contact')} className=' text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] cursor-pointer'> Contact Us</p>
                        </div>
                    </div>
                    <div className="flex-1 md:mt-0 mt-4">
                        <h1 className=' xl:text-[30px] lg:text-[25px] text-[24px] text-white'>Get In Touch</h1>
                        <div className='flex gap-2 py-2'>
                            <img src={LOCATION} alt='location-png' className='xl:w-[17px] md:w-[15px] w-[12px] xl:h-[20px] md:h-[18px] h-[15px] mt-1' />
                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px] xl:pr-[10vh]'>NO 213 1st floor, 7th Cross St, Natesan Nagar, Virugambakkam,<br /> Chennai, Tamil Nadu-600092</p>
                        </div>
                        <div className='flex gap-2 py-2 items-center'>
                            <img src={MAIL} alt='mail-png' className='xl:w-[17px] md:w-[15px] w-[12px] xl:h-[20px] md:h-[18px] h-[15px]' />
                            <a href="https://www.gmail.com" target="_blank" rel="noopener noreferrer">
                                <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px]'>info@namahavfx.com</p>
                            </a>
                        </div>
                        <div className='flex gap-2 py-2 items-center'>
                            <img src={CALL} alt='call-png' className='xl:w-[17px] md:w-[15px] w-[12px] xl:h-[20px] md:h-[18px] h-[15px]' />
                            <p className='text-white xl:text-[20px] lg:text-[15px] md:text-[11px] text-[10px]'>+91 76049 59831</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' flex gap-1 items-center mt-8 xl:pb-2 lg:pb-2 md:pb-2 pb-0'>
                <img src={COPYRIGHT} alt='copyright' className='w-3 md:w-[19px]  md:h-[19px] 2xl:ml-6 md:ml-4 ml-8' />
                <p className=' text-white lg:text-[18px] md:text-[15px] text-[9.63px]'>Copyright by Namaha VFX</p>
            </div>
        </div>
    )
}

export default Footer