import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LOGO from "../../assets/images/LOGO copy.png";

const EnterPassword = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.trim() === '') {
      alert('Please enter the password.');
      return;
    }
    if (password === 'Namahavfx@123') {
      onPasswordSubmit(true);
      const from = location.state?.from || '/Showreel';
      navigate(from, { replace: true });
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className='bg-img'>
      <div className='md:pt-3'>
        <div className="max-w-1440 w-[90%] mx-auto  overflow-hidden">
          <nav className="flex flex-wrap items-center justify-between md:w-full text-lg text-gray-700">
            <div className="flex justify-center md:justify-start w-full md:w-auto md:mt-0 mt-[25px]">
              <a onClick={() => navigate("/")}>
                <img src={LOGO} alt='logo'
                  className="xl:w-[89px] lg:w-[80px] md:w-[48px] w-[44px] xl:h-[89px] lg:h-[80px] md:h-[48px] h-[44px] cursor-pointer" />
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className="flex justify-center items-center h-screen">
        <form onSubmit={handleSubmit} className="bg-black p-8 rounded shadow-lg">
          <div>
            <label htmlFor="password" className="block mb-2 text-white">Enter Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#272727] border border-white text-gray-700 lg:h-[50px] h-[50px] rounded  px-3 mb-3 mb-3 leading-tight focus:outline-none focus:bg-white" />
          </div>
          <button className='border border-[#9F1E0B] bg-[#1c0c09] text-white xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] mt-2  lg:h-[50px] h-[50px] font-thin lg:text-[14px] md:text-[10px] text-[12px] rounded-[8px]'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default EnterPassword;